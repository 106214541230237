<template>
  <div class="bg-white rounded-b flex-col">
    <div
      class="bg-white px-2 text-left text-sm my-2"
      v-if="sale.appliedProductDiscounts"
    >
      <p class="font-semibold mb-1">{{ lang["AppliedDiscounts"][language] }}</p>
      <div
        class="flex justify-start items-center"
        v-for="discount in sale.appliedProductDiscounts"
        :key="discount"
      >
        <box-icon type="solid" color="#17A2B8" name="badge-check"></box-icon>
        <p class="text-blue-800 ml-2">{{ discount }}</p>
      </div>
    </div>
    <div class="bg-blue-100 py-1">
      <div
        class="
          w-11/12
          mx-auto
          flex
          justify-between
          px-1
          py-1
          text-blue-800 text-sm
        "
      >
        <div class="w-1/2 flex text-left">
          <p>{{ sale.productAmount }}</p>
          <p class="pl-1">{{ $tc("item", sale.productAmount) }}</p>
        </div>
        <div
          v-if="parseFloat(sale.totalDiscountAmount) > 0"
          class="w-1/2 text-right font-semibold"
        >
          <p>
            - {{ invoiceCurrency }}
            {{ parseFloat(sale.totalDiscountAmount).toFixed(2) }}
            {{ lang["discount"][language] }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="
        w-11/12
        mx-auto
        p-1
        flex
        justify-between
        items-center
        py-3
        text-blue-600
      "
    >
      <div class="text-left">
        <p class="text-sm">{{ $t("total.excVat") }}</p>
        <p class="font-medium">
          {{ invoiceCurrency }}{{ parseFloat(sale.calcSubtotal).toFixed(2) }}
        </p>
      </div>
      <div>
        <p class="text-sm">
          {{ $t("total.incVat") }} + {{ $tc("giftcard", 2) }}
        </p>
        <p class="font-bold text-blue-800 text-2xl">
          {{ invoiceCurrency }}
          {{
            (
              rounded(sale.balance) -
              Math.abs(rounded(sale.totalDiscountAmount))
            ).toFixed(2)
          }}
        </p>
        <p class="font-bold text-blue-800 text-2xl">
          {{ invoiceCurrency }}
          {{ rounded(balance).toFixed(2) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lang from "../../../assets/lang";
import { round } from "../../../helpers/round";

export default {
  name: "SaleFooter",
  computed: {
    ...mapGetters(["sale", "invoiceCurrency", "language", "balance"]),
  },
  setup() {
    const rounded = (value) => {
      return round(value);
    };
    return {
      lang,
      rounded,
    };
  },
};
</script>

<style scoped></style>
