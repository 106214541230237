<template>
  <div class="rounded h-full">
    <div
      v-if="hasRightPanelInvoice || hasRightPanelReceipt"
      class="flex h-17/20"
    >
      <Payment class="w-1/2 h-full rounded-t"></Payment>
      <RightPanel class="w-1/2 bg-gray-100 rounded-t"></RightPanel>
    </div>
    <div v-else class="flex h-17/20">
      <Payment class="w-full h-full rounded-t"></Payment>
    </div>
    <div class="h-3/20 bg-blue-100 rounded-b relative">
      <RightPanelFooter></RightPanelFooter>
    </div>
  </div>
</template>

<script>
import Payment from "@/components/Content/Payment/Payment";
import RightPanel from "@/components/Content/RightPanel/RightPanel";
import RightPanelFooter from "@/components/Content/RightPanelFooter";
import { mapGetters } from "vuex";

export default {
  name: "RightContent",
  components: {
    RightPanelFooter,
    Payment,
    RightPanel,
  },
  setup() {
    const isRightPanelActive = function () {
      //return store.getters.applicationSettings.loyalty_enabled;
      return false;
      /* return true;
      return false; */
    };
    return {
      isRightPanelActive,
    };
  },
  computed: {
    ...mapGetters([
      "applicationSettings",
      "hasRightPanelInvoice",
      "hasRightPanelReceipt",
    ]),
  },
};
</script>

<style scoped>
</style>