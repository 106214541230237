export default {
  backToSale: "back to sale",
  customerReward: {
    given: "this customer can be given a reward",
    notGiven: "this customer can't be given a reward",
  },
  useLoyaltyCredit: "use loyalty credit",
  addLoyaltyAmountToCustomer: "add loyalty amount to customer",
  recentLoyaltyActions: "recent loyalty actions",
  item: "item | items",
  giftcard: "giftcard | giftcards",
  total: {
    incVat: "total.incl.VAT",
    excVat: "total.excl.VAT ",
    total: "total",
  },
  shopDetailsLoading: "shop details loading",
  byDefault: {
    points: "point(s) per",
    default: "by default",
    spentOfThisSale: "spent of this sale",
    ofThisSale: "of this sale",
  },
  saleAlreadyCompleted: "sale already completed",
  returnToLightSpeed: "return to lightspeed",
  novalidCustomerAttachedToThisSale:
    "No valid customer is attached to this sale",
};
