<template>
  <div
    :class="[
      'my-6',
      hasRightPanelInvoice || hasRightPanelReceipt ? 'w-18/20' : '',
    ]"
  >
    <p class="font-semibold text-l">INVOICING</p>
    <p class="font-medium text-base text-gray-400 my-1">
      Convert sale to invoice?
    </p>
    <div class="mt-2">
      <label for="comment">Add a comment</label>
      <input
        @keyup="saveNote"
        v-model="note"
        class="border-2 h-10 rounded w-full pl-2"
        type="text"
        name="comment"
        id="comment"
        placeholder="Comments"
      />
    </div>
    <div class="my-3">
      <div class="flex justify-between my-1">
        <label class="w-4/6 text-sm" for="email"
          >Send this invoice to customer?</label
        >
        <Switch class="w-1/6 items-end" v-model:checked="sendMail" />
      </div>
      <input
        class="border-2 h-10 rounded w-full pl-2 mb-2"
        type="text"
        name="email"
        id="email"
        v-model="email"
      />
      <input
        class="border-2 h-10 rounded w-full pl-2"
        type="text"
        name="email2"
        id="email2"
        v-if="email != ''"
        v-model="email2"
        placeholder="email address"
      />
    </div>
  </div>
</template>

<script>
import Switch from "@/components/General/Switch";
import { ref } from "vue";
import store from "@/store/store";
import { mapGetters } from "vuex";

export default {
  name: "Invoice",
  components: { Switch },
  computed: {
    ...mapGetters([
      "applicationSettings",
      "customer",
      "sendMail",
      "hasRightPanelInvoice",
      "hasRightPanelReceipt",
    ]),
    sendMail: {
      get() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        return store.getters.sendMail;
      },
      set(value) {
        //switching this button triggers unwanted behaviour
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        store.commit("setSendMail", value);
      },
    },
    email: {
      get() {
        return store.getters.email;
      },
      set(value) {
        store.commit("setEmail", value);
      },
    },
    email2: {
      get() {
        return store.getters.email2;
      },
      set(value) {
        store.commit("setEmail2", value);
      },
    },
  },
  setup() {
    let note = ref("");

    const saveNote = function () {
      store.commit("saveNote", note.value);
    };
    return {
      note,
      saveNote,
    };
  },
};
</script>

<style scoped>
</style>